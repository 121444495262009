import React, { Component } from 'react';
import '../../node_modules/react-vis/dist/style.css';
import {RadialChart} from 'react-vis';

class Chart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            chart: props.chart,
            wrapperClass: props.classData
        }
    }

    widthChange() {
      if(window.innerWidth > 400) {
        return 450
      } else {
        return 200
      }
    }
    innerRadius() {
      if(window.innerWidth > 400) {
        return 212
      } else {
        return 90
      }
    }

    render() {
      return (
        <div className={`chart_wrapper ${this.state.wrapperClass}`}>
            <RadialChart 
            width={this.widthChange()} 
            colorType="literal" 
            innerRadius={50} 
            radius={this.innerRadius()}
            height={this.widthChange()} 
            getLabel={d => d.name}
            style={{stroke: '#fff', strokeWidth: 2}}
            data={this.state.chart} />
        </div>
      );
    }
  }
  
export default Chart;