import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import './css/bootstrap.css';
import './css/style.scss';

import ChartSection from './components/chartSection';

import CompanyLogo from './assets/logo/logo.svg'
import Section1Laptop from './assets/lap1.png'
import Graph1 from './assets/grap1.png'
import Graph2 from './assets/grap2.png'
import Graph3 from './assets/grap3333.jpg'
import Phone1 from './assets/phone1.png'
import Phone2 from './assets/phone2.png'
import Phone3 from './assets/phone3.png'

import Yandex from './assets/yandexinfo-min.jpg'

import Banner1 from './assets/banner-min.png'
import Banner2 from './assets/banner2-min.png'

import BannerComp1 from './assets/comp1-min.png'
import BannerComp2 from './assets/comp2-min.png'

import ArticleBanner1 from './assets/main-news1-min.png'
import ArticleBanner2 from './assets/main-news2-min.png'

import LentaBanner1 from './assets/lenta1-min.png'
import LentaBanner2 from './assets/lenta2-min.png'

import GoogleAnBanner from './assets/grap3.png'

class Front extends Component {
    render() {
        return (
            <main>
                <div className="section_top">
                    <h1>Коммерческое предложение</h1>
                    <div className="empty_block">
                        <div className="top_text">
                            по размещению рекламы на сайте <a className="logo_link" href="https://yk-news.kz/" target="_blank"></a>
                        </div>
                    </div>
                    {/*<div className="company_block">
                        <p>для Евразийского банка</p>
                        <img src={CompanyLogo} alt="для Евразийского банка" />
                    </div>*/}
                </div>
                <div className="section section_1 mobile_hide">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col_contact">
                                <h4>Контакты в Усть-Каменогорске</h4>
                                <ul>
                                    <li>
                                        <i className="ico ico_location ico_pattern"></i>Усть-Каменогорск, ул. Казахстан, 87/4
                                    </li>
                                    <li>
                                        <i className="ico ico_mail ico_pattern"></i><a href="mailto:reklama@idregion.kz">reklama@idregion.kz</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6 col_contact">
                                <h4>Отдел рекламы</h4>
                                <ul>
                                    <li>
                                        <i className="ico ico_phone ico_pattern"></i><span>Телефон: </span> <a href="tel:+7 (7232) 70-40-42"> +7 (7232) 70-40-42</a>
                                    </li>
                                    <li>
                                        <i className="ico ico_wht ico_pattern"></i><span>WhatsApp: </span> <a href="https://api.whatsapp.com/send?phone=+77053161213"> +7 (705) 316-12-13</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section section_2">
                    <div className="container">
                        <h2 className="big_name">
                            Почему <a className="logo_link" href="https://yk-news.kz/" target="_blank"></a> ?
                        </h2>
                        <p className="subtitle">Сайт yk-news.kz — лидер среди сайтов Усть-Каменогорска и ВКО</p>
                        <div className="laptop_image1">
                            <img className="res_img" src={Section1Laptop} />
                        </div>
                        <div className="info_1">
                            <label>Среднее количество пользователей:</label>
                            <div className="info_items">
                                
                                
                                <div className="item">
                                    <div className="name">В ДЕНЬ</div>
                                    <div className="val">
                                        <span className="sum">40 000</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="name">В МЕСЯЦ</div>
                                    <div className="val">
                                        <span className="sum">630 000</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="name">В ГОД</div>
                                    <div className="val">
                                        <span className="sum">2 700 000</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ChartSection />
                <div className="section section_3">
                    <div className="container">
                        <h2 className="section_title">Политика открытости</h2>
                        <div className="subtitle">Мы готовы предоставить данные по сайту в режиме он-лайн</div>
                        <div className="row">
                            <div className="col-md-6">
                                <img src={Graph1} className="res_img" alt="Мы готовы предоставить данные по сайту в режиме он-лайн" />
                            </div>
                            <div className="col-md-6">
                                <img src={Graph2} className="res_img" alt="Мы готовы предоставить данные по сайту в режиме он-лайн" />
                            </div>
                            <div className="col-md-12 col_gp_name col_gp_padding yandex_label">
                            <a href="https://metrika.yandex.ru/stat/?id=41730304&amp;from=informer"
target="_blank" rel="nofollow" className="yandex_inform app_link"><img src={Yandex}
 alt="Yandex Metrika" title="Яндекс.Метрика: данные за сегодня (просмотры, визиты и уникальные посетители)" className="ym-advanced-informer" data-cid="41730304" data-lang="ru" /></a>
                            </div>
                            <div className="col-md-12 col_graph3">
                                <img src={Graph3} className="res_img graph3_img pc_an" alt="Мы готовы предоставить данные по сайту в режиме он-лайн" />
                                <img src={GoogleAnBanner} className="res_img graph3_img mb_an" alt="Мы готовы предоставить данные по сайту в режиме он-лайн" />
                            </div>
                            <div className="col-md-12 col_gp_name">
                                Google Аналитика
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section section_4">
                    <div className="container">
                        <h2 className="section_title">Наше неоспоримое преимущество</h2>
                        <div className="section_4_text">
                            <p>
                                Разработчики <a href="https://yk-news.kz/" target="_blank">yk-news.kz</a> создали мобильную версию сайта. <br/>
                                Она высоко функциональна и отличается удобством
                                интерфейса
                            </p>
                            <p>
                                Мобильная версия доступна для скачивания в  <a className="app_link" href="https://play.google.com/store/apps/details?id=com.region.yknews&referrer=appmetrica_tracking_id%3D746947933148024757%26ym_tracking_id%3D5406823080629742923">Play Market</a>
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col_top_pd">
                                <img src={Phone1} className="res_img" alt="Наше неоспоримое преимущество" />
                            </div>
                            <div className="col-md-4 col_top_pd">
                                <img src={Phone2} className="res_img" alt="Наше неоспоримое преимущество" />
                            </div>
                            <div className="col-md-4">
                                <img src={Phone3} className="res_img" alt="Наше неоспоримое преимущество" />
                            </div>
                            <div className="col-md-12">
                                <div className="red_alert">Наша аудитория современна, молода, мобильна!</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section_bg">
                    <div className="section section_5">
                        <div className="container">
                            <h2 className="section_title">
                                <span className="mobile_hide">Баннеры для мобильной версии</span>
                                <span className="pc_hide">Баннеры на сайте </span>
                            </h2>
                            <p className="text_5">
                                Стоимость из расчета ротации 25%<br/>
                                Формат баннера: <i>анимированный баннер в HTML5,<br/>
                                размером 480 на 290 пикселей</i>
                            </p>
                            <div className="pc_hide mobile_title">
                                Баннеры для мобильной версии сайта
                            </div>
                            <div className="mobile_bn">
                                <div className="item item_right">
                                    <img src={Banner1} className="res_img" alt="Баннеры для мобильной версии" />
                                </div>
                                <div className="item">
                                    <div className="item_wrap">
                                        <h5>Новость</h5>
                                        <div className="price">20.000</div>
                                        <div className="price_old"><span>25.000</span> тг/нед.</div>
                                        <div className="price_sum">цена со скидкой -20%</div>
                                    </div>
                                </div>
                            </div>
                            <div className="mobile_bn mobile_bn_last">
                                <div className="item">
                                    <div className="item_wrap t_r">
                                        <div className="text_right">
                                            <h5>Лента новостей</h5>
                                            <div className="price">12.000</div>
                                            <div className="price_old"><span>15.000</span> тг/нед.</div>
                                            <div className="price_sum">цена со скидкой -20%</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item item_left">
                                    <img src={Banner2} className="res_img" alt="Баннеры для мобильной версии" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section section_6">
                        <div className="container">
                            <h2 className="section_title section_title_m_r">
                                <span>Баннеры для полной версии</span>
                            </h2>
                            <p className="text_5 mobile_hide">
                                Стоимость из расчета ротации 25%<br/>
                                Формат баннера: <i>
                                анимированный баннер в HTML5,<br/>
                                размером 480 на 290 пикселей
                                </i>
                            </p>
                            <div className="pc_bn">
                                <div className="item item_lf">
                                    <img src={BannerComp1} className="res_img" alt="Баннеры для полной версии" />
                                </div>
                                <div className="item item_rg">
                                    <div className="rg_wrap">
                                        <div className="price">4000</div>
                                        <div className="price_old"><span>5000</span> тг/нед.</div>
                                        <div className="price_sum">цена со скидкой -20%</div>
                                    </div>
                                </div>
                            </div>

                            <div className="pc_bn">
                                <div className="item item_lf">
                                    <img src={BannerComp2} className="res_img" alt="Баннеры для полной версии" />
                                </div>
                                <div className="item item_rg">
                                    <div className="rg_wrap rg_top">
                                        <div className="price">4000</div>
                                        <div className="price_old"><span>5000</span> тг/нед.</div>
                                        <div className="price_sum">цена со скидкой -20%</div>
                                    </div>
                                    <div className="rg_wrap">
                                        <div className="price">4000</div>
                                        <div className="price_old"><span>5000</span> тг/нед.</div>
                                        <div className="price_sum">цена со скидкой -20%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section section_7">
                    <div className="container">
                        <h2 className="section_title">
                            <span className="mobile_hide">Статьи. Главная новость</span>
                            <span className="pc_hide">Статьи на сайте </span>
                        </h2>
                        <p className="text_5">
                            Объём: до 5000 знаков.<br/>
                            Фотографии: <div className="text_sep"></div>до 7-ми в тексте <br/>
                            <span className="text_lf_pd">до 15-ти в фотогалерее</span>
                        </p>
                        <div className="pc_hide mob_title_2">Главная новость</div>
                        <div className="mobile_bn mobile_bn_article">
                            <div className="item">
                                <div className="item_wrap t_r">
                                    <div className="text_right">
                                        <h5>Главная новость</h5>
                                        <div className="price">8000</div>
                                        <div className="price_old"><span>10.000</span> тг/день.</div>
                                        <div className="price_sum">цена со скидкой -20%</div>
                                    </div>
                                </div>
                            </div>
                            <div className="item item_left item_left_big">
                                <img src={ArticleBanner1} className="res_img" alt="Статьи. Главная новость" />
                            </div>
                        </div>
                        <div className="one_banner">
                            <img src={ArticleBanner2} className="res_img" alt="Статьи. Главная новость" />
                        </div>
                    </div>
                </div>
                <div className="section section_8">
                    <div className="container">
                        <h2 className="section_title section_title_m_r">
                            <span className="mobile_hide">Статьи. Лента новостей</span>
                            <span className="pc_hide">В ленте новостей</span>
                        </h2>
                        <p className="text_5">
                            Объём: до 5000 знаков.<br/>
                            Фотографии: до 7-ми в тексте <br/>
                            <span className="text_lf_pd">до 15-ти в фотогалерее</span>
                        </p>
                        <div className="mobile_bn mobile_bn_article">
                            <div className="item">
                                <div className="item_wrap t_r">
                                    <div className="text_right">
                                        <h5>Лента новостей</h5>
                                        <div className="price">24.000</div>
                                        <div className="price_old"><span>30.000</span> тг/день.</div>
                                        <div className="price_sum">цена со скидкой -20%</div>
                                    </div>
                                </div>
                            </div>
                            <div className="item item_left item_left_big">
                                <img src={LentaBanner1} className="res_img" alt="Статьи. Главная новость" />
                            </div>
                        </div>
                        <div className="one_banner">
                            <img src={LentaBanner2} className="res_img" alt="Статьи. Главная новость" />
                        </div>
                    </div>
                </div>
                <div className="section section_9">
                    <div className="container">
                        <div className="dop_label">Дополнительная услуга:</div>
                        <div className="dop_text">
                            Удержание статьи на верхних позициях в ленте новостей<br/>
                            -  10.000 тг./день
                        </div>
                    </div>
                </div>
                <div className="section section_1 pc_hide">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col_contact">
                                <h2 className="section_title">
                                    Контакты
                                </h2>
                                <h4>Контакты в Усть-Каменогорске</h4>
                                <ul>
                                    <li>
                                        <i className="ico ico_location ico_pattern"></i>Усть-Каменогорск, <br/>ул. Казахстан, 87/4
                                    </li>
                                    <li>
                                        <i className="ico ico_mail ico_pattern"></i><a href="mailto:reklama@idregion.kz">reklama@idregion.kz</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6 col_contact">
                                <h4>Отдел рекламы</h4>
                                <ul>
                                    <li>
                                        <i className="ico ico_phone ico_pattern"></i><span>Телефон: </span> <a href="tel:+7 (7232) 70-40-42"> +7 (7232) 70-40-42</a>
                                    </li>
                                    <li>
                                        <i className="ico ico_wht ico_pattern"></i><span>WhatsApp: </span> <a href="https://api.whatsapp.com/send?phone=+77053161213"> +7 (705) 316-12-13</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

ReactDOM.render(
    <Front />,
    document.getElementById('app')
);